.kh-section-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 2rem 30px;
  width: 100%;
  height: 100%;
  /* margin-top: -3%; */
  background: url('../../assets/images/landing_page_v2/hero-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

/* Create a pseudo-element for the blur effect */
.kh-section-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit; /* Inherit the background image */
  filter: blur(5px); /* Adjust the blur radius as needed */
  z-index: -1; /* Place the pseudo-element behind the content */
}

.kh-top-section {
  position: relative;
  top: 20%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
}

.kh-top-left-section {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.kh-top-left-section span {
  color: #fff;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 75px;
}

.kh-top-right-section {
  display: flex;
  width: 60%;
}

.kh-top-right-section span {
  color: #fff;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.kh-bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 30px 3rem;
}

@media screen and (max-width: 768px) {
  /* .kh-section-container {
    padding-top: 6rem;
  } */

  .kh-top-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .kh-top-left-section {
    width: 100%;
  }

  .kh-top-right-section {
    width: 100%;
  }

  .kh-top-right-section span {
    font-size: 20px;
  }

  .kh-divider-section {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  /* .kh-section-container {
    padding-top: 6rem;
  } */

  .kh-top-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }

  .kh-divider-section {
    display: none;
  }

  .kh-top-left-section {
    width: 100%;
    padding-bottom: 1rem;
    text-align: center;
  }

  .kh-top-left-section span {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 45px;
  }

  .kh-top-right-section {
    width: 100%;
  }

  .kh-bottom-section {
    padding: 0 10px;
  }
}
