.state-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 2rem 30px;
  width: 100%;
  height: 100%;
}

.state-top-section {
  position: relative;
  top: 20%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
}

.state-top-left-section {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.state-top-left-section span {
  color: #143461;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 75px;
}

.state-top-right-section {
  display: flex;
  width: 60%;
}

.state-top-right-section span {
  color: #434343;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.state-section-views {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .state-section-container {
    padding-top: 1rem;
  }

  .state-divider-section {
    display: none;
  }

  .state-top-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .state-top-left-section,
  .state-top-right-section {
    width: 100%;
  }

  .state-top-right-section span {
    font-size: 20px;
  }

  .state-section-views {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .state-section-container {
    padding-top: 1rem;
  }

  .state-top-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px !important;
  }

  .state-divider-section {
    display: none;
  }

  .state-top-left-section {
    width: 100%;
    text-align: center;
  }

  .state-top-left-section span {
    color: #143461;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
  }

  .state-top-right-section {
    width: 100%;
  }
}
