.datapolicy-section-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  height: 100%;
  /* margin-top: 8%; */
}

.datapolicy-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 0 30px;
  z-index: 3;
  position: relative;
  top: 3%;
  left: 0%;
}

.datapolicy-logos {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  cursor: default;
  cursor: pointer;
}

.undp-datapolicy-logo {
  height: 80px;
  /* width: 100px; */
}

.nabard-datapolicy-logo {
  height: 80px;
  /* width: 100px; */
}

.dicra-datapolicy-logo {
  height: 90px;
  width: 280px;
}

.datapolicy-heading {
  position: relative;
  top: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  width: 100%;
}

.datapolicy-heading-title {
  color: #143461;
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 2rem;
}

.datapolicy-page-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.datapolicy-page-form-radio-btn {
  display: flex;
  gap: 35px;
  background: #f8f8f8;
  border-radius: 8px;
  color: #434343;
  padding: 20px;
  position: relative;
  top: 30px;
  left: 50px;
  width: 84%;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.datapolicy-page-add-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  top: 30px;
  right: 38px;
  float: right;
  font-size: 21px;
  border-radius: 50px;
  background-color: #143461;
  white-space: nowrap;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.datapolicy-page-add-btn:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-out;
  text-decoration: none;
  color: #fff;
  background-color: #143461;
}

.datapolicy-page-add-btn.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #143461;
  border-color: #143461;
}

.datapolicy-page-cards-container {
  justify-content: center;
  display: flex;
  margin-top: 30px;
  gap: 30px;
  padding: 40px;
  flex-wrap: wrap;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 2rem;
}

.datapolicy-page-cards {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
}

.datapolicy-page-card-image {
  width: 100%;
  height: 250px;
}

.datapolicy-page-card-title {
  color: #143461;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.06px;
  letter-spacing: 0.197px;
}

.datapolicy-page-card-text {
  color: #434343;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0.197px;
}

.datapolicy-page-modal-image-cover {
  position: relative;
  width: 100%;
  height: 300px;
  object-fit: cover;
  padding: 10px;
  filter: blur(0.5px);
}

.datapolicy-page-modal-centered {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  text-align: center;
}

.datapolicy-page-modal-title {
  color: #143461;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.06px;
  letter-spacing: 0.197px;
}

@media screen and (max-width: 768px) {
  .datapolicy-section-container {
    padding: 0 30px;
    padding-top: 1rem;
  }

  .datapolicy-heading-title {
    font-size: 36px;
  }

  .datapolicy-page-row {
    flex-direction: column;
  }

  .datapolicy-page-form-radio-btn {
    width: 90%;
    left: 5%;
    justify-content: center;
    align-items: center;
  }
  .datapolicy-page-add-btn {
    top: 60%;
    right: 3.5%;
  }

  .datapolicy-page-cards-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 30px;
    padding: 0 20px;
    flex-wrap: wrap;
  }

  .datapolicy-page-cards {
    width: 47%;
  }

  .datapolicy-page-card-image {
    height: 150px;
  }

  .datapolicy-page-card-title {
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 30px;
  }

  .datapolicy-page-modal-title {
    font-size: 20px;
    line-height: 30px;
  }

  .datapolicy-page-card-text {
    font-size: 16px;
    line-height: 30px;
  }

  .datapolicy-page-modal-centered {
    font-size: 20px;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 468px) {
  .datapolicy-section-container {
    padding: 0 10px;
    padding-top: 1rem;
  }

  .datapolicy-heading {
    padding: 0 10px;
  }

  .datapolicy-heading-title {
    font-size: 36px;
  }

  .datapolicy-page-row {
    flex-direction: column;
  }

  .datapolicy-page-form-radio-btn {
    width: 90%;
    left: 5%;
    flex-wrap: wrap;
  }

  .datapolicy-page-add-btn {
    top: 60%;
    right: 3.5%;
  }

  .datapolicy-page-cards-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 30px;
    padding: 0 20px;
    flex-wrap: wrap;
  }

  .datapolicy-page-cards {
    width: 100%;
  }

  .datapolicy-page-card-image {
    height: 150px;
  }

  .datapolicy-page-card-title {
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 30px;
  }

  .datapolicy-page-card-text {
    font-size: 16px;

    line-height: 30px;
  }

  .datapolicy-header {
    padding: 0;
    gap: 10px;
    left: 0%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .undp-datapolicy-logo {
    height: 60px;
    /* width: 50px; */
  }

  .nabard-datapolicy-logo {
    height: 60px;
    /* width: 100px; */
  }

  .dicra-datapolicy-logo {
    height: 90%;
    width: 160px;
    cursor: pointer;
  }
}

.react-pdf__Page__canvas{
  display: block;
  user-select: none;
  width: 700px !important;
  height: auto !important;
  /* margin-left: 17rem; */
}
.pdf-wrapper{
text-align:  -webkit-center !important;
margin: 20px;
}