.partners-section-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 1rem 30px;
  width: 100%;
  height: 100%;
  /* margin-top: -3%; */
}

.partners-top-section {
  position: relative;
  top: 5%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px 30px 0px;
}

.partners-top-left-section {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.partners-top-left-section span {
  color: #143461;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 75px;
}

.partners-top-right-section {
  display: flex;
  width: 60%;
}

.partners-top-right-section span {
  color: #434343;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.partners-card-views {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.partners-card-views-container {
  /* display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  padding: 30px 30px 4rem; */
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 5 cards per row */
  gap: 20px;
  padding: 30px;
  justify-items: center;
}

@media screen and (max-width: 1024px) {
  .partners-top-section {
    flex-direction: column;
  }

  .partners-top-left-section,
  .partners-top-right-section {
    width: 100%;
  }

  .partners-divider-section {
    display: none;
  }
  .partners-card-views-container {
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .partners-top-section {
    flex-direction: column;
  }

  .partners-top-left-section,
  .partners-top-right-section {
    width: 100%;
  }

  .partners-divider-section {
    display: none;
  }
  .partners-card-views-container {
    justify-content: space-between;
  }
}

@media screen and (max-width: 480px) {
  .partners-top-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
  }

  .partners-divider-section {
    display: none;
  }

  .partners-top-left-section {
    width: 100%;
    padding-bottom: 1rem;
  }

  .partners-top-left-section span {
    color: #143461;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
  }

  .partners-top-right-section span {
    color: #434343;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }

  .partners-top-right-section {
    width: 100%;
  }

  .partners-card-views-container {
    padding: 30px 10px;
    gap: 10px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
  }

  .custom-card {
    width: 45%;
    padding: 10px;
    height: 130px;
  }

  .custom-card img {
    width: 100%;
  }
}

@media (min-width: 1900px) {
  .partners-card-views-container {
    display: grid;
    grid-template-columns: repeat(6, minmax(290px, 1fr)); /* 5 cards per row */
    gap: 20px;
    padding: 30px;
    justify-items: center; /* Align items to the start of the grid cells */
  }

  .custom-card {
    width: 100%;
  }
}
