/* .tile-view-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 30px 30px;
} */

.tile-view-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 cards per row */
  gap: 20px;
  padding: 30px;
  justify-items: start; /* Align items to the start of the grid cells */
}

@media screen and (max-width: 1024px) {
  .tile-view-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 30px 30px;
  }
}

@media (min-width: 1900px) {
  .tile-view-container {
    justify-content: center; /* Center the items horizontally */
    display: grid;
    grid-template-columns: repeat(6, minmax(285px, 1fr));
  }
}

@media screen and (max-width: 480px) {
  .tile-view-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 30px 30px;
    padding: 30px 10px !important;
  }
}
