.featured-cards-container {
  width: 100%;
}

.featured-cards-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}

.featured-cards-header span {
  color: #143461;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px;
}

.featured-cards-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.featured-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 24%;
  height: 420px;
  gap: 10px;
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.featured-card-image {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #8e8d94;
  border-radius: 2px;
}

.featured-card-image img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.featured-card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  height: 45%;
}

.featured-card-title {
  color: #143461;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.197px;
  max-height: 60px; /* Set the maximum height for 2 lines of text */
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.featured-card-subtitle {
  color: #434343;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.featured-card-btn {
  position: relative;
  bottom: 0%;
}

@media screen and (max-width: 1024px) {
  .featured-card {
    height: 470px;
    width: 25%;
  }

  .featured-card-image img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .featured-card-btn {
    position: relative;
    bottom: 0;
  }

  .featured-card-title {
    line-height: 24px;
  }

  .featured-card-subtitle {
    line-height: 24px;
  }

  .featured-card-btn {
    position: relative;
    bottom: 0%;
  }
}

@media screen and (max-width: 768px) {
  .featured-cards-wrapper {
    /* flex-direction: column; */
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .featured-card {
    width: 48%;
    height: 430px;
  }

  .featured-card-btn {
    position: relative;
    bottom: 0;
  }

  .featured-card-title {
    line-height: 24px;
  }

  .featured-card-subtitle {
    line-height: 24px;
  }

  .featured-card-btn {
    position: relative;
    bottom: 0%;
  }
}

@media (min-width: 200px) and (max-width: 480px) {
  .featured-cards-header {
    align-items: flex-end;
    flex-direction: column;
    padding-bottom: 1rem;
  }

  featured-cards-wrapper {
    /* flex-direction: column; */
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .featured-card {
    width: 100%;
    height: 100%;
  }

  .featured-card-image img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .featured-card-btn {
    position: relative;
    bottom: 0;
  }

  .featured-card-title {
    line-height: 28px;
    height: 70%;
  }

  .featured-card-subtitle {
    line-height: 24px;
  }

  .featured-card-btn {
    position: relative;
    bottom: -10%;
  }
}

@media screen and (min-width: 1980px) {
  .featured-card {
    height: 530px;
    width: 21%;
  }

  .featured-cards-header {
    padding: 0 4.5%;
  }

  .featured-card-image img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .featured-card-btn {
    position: relative;
    bottom: 0;
  }

  .featured-card-title {
    line-height: 28px;
    height: 45%;
    max-height: 100%;
    overflow: hidden;
    text-overflow: unset;
    white-space: unset;
    font-size: 25px;
  }
}
