.hero-section {
  position: relative;
  top: 20%;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 30px;
  background-image: url('../../assets/images/landing_page_v2/hero-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.hero-section__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  filter: blur(0.5px);
}

.hero-section__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}

.hero-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 0 30px;
  z-index: 3;
  position: relative;
  top: 3%;
  left: 0%;
}

.hero-logos {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  cursor: default;
}

.undp-hero-logo {
  height: 80px;
  /* width: 100px; */
}

.nabard-hero-logo {
  height: 80px;
  /* width: 100px; */
}

.dicra-hero-logo {
  height: 90px;
  width: 280px;
}

.hero-top-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-top-btn-wrapper {
  border-radius: 5px;
  background-color: #143461;
  white-space: nowrap;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.hero-top-btn-wrapper:hover {
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
  background-color: #143461;
  color: #fff;
}

/* LEFT SECTION */
.hero-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  gap: 20px;
  z-index: 3;
  filter: drop-shadow(1px 1px 0 #000);
  animation: fadeIn 0.5s ease-in-out;
}

.hero-heading {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.header-divider {
  width: 80%;
  border: 1px solid #fff;
  opacity: 1;
  margin: 0;
}

.hero-heading span {
  color: #fff;
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: -0.56px;
}

.hero-description {
  width: 70%;
  text-align: center;
}

.hero-description span {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
}

/* SECTIONS DIVIDER */
.section-divider {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 10%;
}

.divider-content-image-1 {
  position: absolute;
  top: 17%;
}

.divider-content-image-2 {
  position: absolute;
  top: 33%;
}

/* RIGHT SECTION */
.hero-right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 100%;
}

.hero-right img {
  width: 590px;
  height: 480px;
}

.hero-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.hero-btn-wrapper:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-out;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    /* height: 735px; */
  }

  .hero-heading span {
    font-size: 33px;
  }

  .hero-description span {
    font-size: 18px;
    line-height: 30px;
  }

  .hero-top-btn-wrapper {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .hero-section {
    flex-direction: column;
    /* height: 800px; */
  }

  .hero-content-wrapper {
    padding: 0 10px;
    top: 40%;
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: none;
  }

  .hero-heading {
    width: 100%;
    text-align: center;
  }

  .hero-description {
    width: 100%;
    text-align: center;
  }

  .hero-heading span {
    font-size: 30px;
  }

  .hero-description span {
    font-size: 16px;
    line-height: 25px;
  }

  .hero-header {
    padding: 0;
    gap: 10px;
    left: 0%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .undp-hero-logo {
    height: 60px;
    /* width: 50px; */
  }

  .nabard-hero-logo {
    height: 60px;
    /* width: 100px; */
  }

  .dicra-hero-logo {
    height: 90%;
    width: 160px;
    cursor: pointer;
  }

  .hero-top-btn-wrapper {
    display: none;
  }
}

@media screen and (min-width: 1980px) {
  .header-divider {
    width: 50%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
