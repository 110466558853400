.footer-container {
  background-color: #143461;
  display: flex;
  padding: 30px;
  align-items: center;
}

.footer-left {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  padding: 0 30px; */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 30px;
}

.footer-logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  gap: 20px;
}

.undp-footer-logo {
  height: 80px;
  /* width: 100px; */
}

.nabard-footer-logo {
  height: 80px;
}

.dicra-footer-logo {
  height: 90px;
  width: 280px;
  cursor: pointer;
}

.footer-description {
  color: #fff;
  font-size: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-dpg-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* .footer-dpg-heading span {
  color: #fff;

  font-style: normal;
  font-weight: 600;
  line-height: normal;
} */

.footer-dpg-heading img {
  width: 65px;
  cursor: pointer;
}

.footer-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  padding: 0 30px;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;
  height: 100%;
}

.footer-link-items-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;
  height: 100%;
  gap: 8px;
}

.footer-link-title {
  padding-bottom: 1rem;
}

.footer-link-title span {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.footer-link,
.footer-addres-desc {
  color: #434343;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-decoration: none;
  padding: 2px;
}

.footer-link:hover {
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease-out;
}

.footer-addres-desc {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .footer-links-container {
    padding-top: 32px;
  }

  .footer-links-wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 468px) {
  .footer-container {
    flex-direction: column;
    padding: 30px 10px;
  }

  .footer-left,
  .footer-right {
    width: 100%;
  }

  .footer-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 30px;
  }

  .footer-link-items {
    margin: 0;
    padding: 10px;
    width: 50%;
  }

  .undp-footer-logo {
    height: 50px !important;
    /* width: 100px; */
  }

  .nabard-footer-logo {
    height: 50px !important;
    /* width: 100px; */
  }
  .dicra-footer-logo {
    height: 70px;
    width: 140px !important;
  }
}
